import { useTranslation } from 'react-i18next';

type TabHeadersProps = {
    currentTab: string,
    setCurrentTab: (value: string) => void,
}

export const TabHeaders = ({ currentTab, setCurrentTab } : TabHeadersProps) => {
    const { t } = useTranslation('modals');

    const getTabClasses = (tab: string) => {
        return `text-xl w-fit ${currentTab === tab ? 'font-bold text-teal-500 border-b-4 border-teal-500 dark:text-teal-600 dark:border-teal-600': 'text-slate-500 hover:opacity-75 transition-all'}`
    }

    return (
        <div className="gird grid-cols-3 px-auto py-4 space-x-4">
            <button onClick={() => setCurrentTab('info')} className={getTabClasses('info')}>{t('infoModal.info')}</button>
            <button onClick={() => setCurrentTab('settings')} className={getTabClasses('settings')}>{t('infoModal.support')}</button>
            <button onClick={() => setCurrentTab('help')} className={getTabClasses('help')}>{t('infoModal.purpose')}</button>
        </div>
    )
}

export const projectLinks = {
    productionUrl: 'https://visualsnowsimulator.com',
    vsiHomepage: 'https://www.visualsnowinitiative.org/',
    vsiDonationLink: 'https://visualsnowinitiative.networkforgood.com/',
    vsiNewsLink: 'https://www.visualsnowinitiative.org/news/',
    vsiActiveStudies: 'https://www.visualsnowinitiative.org/research/active-visual-snow-initiative-vsi-visual-snow-syndrome-research-studies/',
    marathonDonationLink: 'https://visualsnowinitiative.networkforgood.com/projects/228334-running-for-a-clearer-future-support-my-berlin-marathon',
    developersGithub: 'https://github.com/finnianj',
    projectGithub: 'https://github.com/finnianj/visualSnowSimulator',
    contactEmail: 'visualsnowsimulator@gmail.com',
    discordInvite: 'https://discord.gg/9Ga92FuMAy',
    hdriMaps: 'https://polyhaven.com/hdris',
}

import { useState, useRef, useEffect } from 'react'

import { useUI } from '@/components/context';
import { TabHeaders, ModalIcons, InfoModalContent } from './components'

export const InfoModal = ({ setShowInfo }: { setShowInfo: (show: boolean) => void }) => {
    const { darkMode, setDarkMode } = useUI()
    const [currentTab, setCurrentTab] = useState<string>('info')
    const tabRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        // Scroll to top of modal when tab changes
        tabRef.current?.scrollTo(0, 0)
    }, [currentTab])

    return (
        <div ref={tabRef} className={`relative flex flex-col ${darkMode ? 'bg-gray-900 dark' : 'bg-gray-100'} rounded-lg shadow-lg h-[calc(100vh-200px)] sm:h-fit sm:m-4 overflow-y-scroll`}>
            <div className={`${darkMode ? 'bg-gray-900 dark' : 'bg-gray-100'} sticky top-0 pb-0 pt-6 sm:pt-0`}>
                <ModalIcons setShowInfo={setShowInfo} />
                <TabHeaders currentTab={currentTab} setCurrentTab={setCurrentTab} />
            </div>
            <InfoModalContent currentTab={currentTab} />
        </div>
    )
}